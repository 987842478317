import type { FetchError } from 'ofetch'

export default async function useUser() {
  const url = apiPaths.user()
  const { data: user } = useNuxtData<User>(url)

  const refresh = async () => {
    await useData<User>(url)
  }

  if (!user.value) {
    await useData<User>(url)
  }

  const hasPaid = computed(() => user.value?.paid || false)
  const hasPartner = computed(() => !!user.value?.partner)
  const partnerName = computed(() => user.value?.partner?.firstName || 'Partner')
  const partnerOnboarded = computed(() => user.value?.partner?.onboarded)

  return { user, hasPaid, hasPartner, partnerName, partnerOnboarded, refresh }
}

export async function useUpdateUser(payload: Partial<User>) {
  const url = apiPaths.user()
  const { data: user } = useNuxtData<User>(url)

  const { data, pending, error, refresh } = await useData<User>(url, {
    method: 'PATCH',
    headers: { 'Content-type': 'application/json' },
    body: {
      user: payload,
    },
  })

  return { user, error }
}

export async function useUserOnboarding(params: Onboarding) {
  const { data: user } = useNuxtData<User>(apiPaths.user())

  const resp = await $fetch<User>(apiPaths.profileOnboard(), {
    method: 'PATCH',
    body: { user: params },
  })

  // TODO: Check for errors
  user.value = resp

  return { user }
}

export async function useUserInvite(payload: { firstName: string; email?: string }) {
  try {
    const resp = await $fetch(apiPaths.invitesCreate(), {
      method: 'POST',
      body: {
        invite: payload,
      },
    })

    const { refresh: refreshUser } = await useUser()
    refreshUser()
    const { refresh } = await useSectionModules('dashboard')
    await refresh()

    return { resp }
  } catch (err) {
    const error = err as FetchError

    return { error: error.data }
  }
}

export async function useUserProfile(params: ProfileUpdate) {
  const url = apiPaths.profileUpdate()
  const { data: profile } = useNuxtData(url)

  if (!profile.value) {
    const {
      data: profile,
      pending,
      error,
      refresh,
    } = await useData(url, {
      method: 'PATCH',
      headers: { 'Content-type': 'application/json' },
      body: { user: params },
    })

    return { profile }
  }

  return { profile }
}

export async function usePartnerUpdate(params: Partial<ProfileUpdate>) {
  const url = apiPaths.profilePartner()
  const { user } = await useUser()

  try {
    const response = await $fetch<User['partner']>(url, {
      method: 'PATCH',
      headers: { 'Content-type': 'application/json' },
      body: { partner: params },
    })

    if (user.value) {
      user.value.partner = response?.partner
    }
  } catch (error) {
    console.log(error)
  }

  return {}
}
